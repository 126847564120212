:root {
    --d: 2500ms;
    --angle: 90deg;
    --gradX: 100%;
    --gradY: 50%;
    --c1: rgba(168, 239, 255, 1);
    --c2: rgba(168, 239, 255, 0.1);
    --primary: #ff5a19;
    --black: #111111;
    --bg: #1e1e1f;
    --white: #ffffff;
}
html {
    overflow-x: hidden;
}

body {
    font-size: 10px;
    min-height: 100vh;
    font-family: "IBM Plex Sans", system-ui, -apple-system, Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-feature-settings: "ss04" 1;
}

a {
    text-decoration: none;
    color: inherit;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    /* scrollbar-color: var(--primary) var(--primary); */
    /* outline-color: var(--primary) var(--primary); */
    outline-offset: -2px;
    outline-width: 4px;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    max-width: 6px;
    max-height: 6px;
}

*::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.05);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

input[type="text" i] {
    padding: 0;
}

input {
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    outline: none;
}

input::placeholder {
    color: rgba(255, 255, 255, 0.3);
}

.rc-slider {
    padding-top: 20px;
    padding-bottom: 50px;
}

.rc-slider-handle {
    transform: translate(-50%) !important;
}
/* .rc-slider-handle.rc-slider-handle-1 {
  transform: translate(0%) !important;
}
.rc-slider-handle.rc-slider-handle-2 {
  transform: translate(0%) !important;
} */

.rc-slider-rail {
    height: 10px;
    /* background: #2c2a30; */
    box-shadow: none;
}

.rc-slider-track {
    height: 10px;
    box-shadow: none;
}

.rc-slider-dot {
    border: none;
    border-radius: 1px;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    margin-left: -3px;
    top: 2.3px;
    background: white;
}

.rc-slider-dot-active {
    background: var(--primary);
}

.rc-slider-handle {
    background: var(--primary);
    border: none;
    margin-top: -12px;
    border-radius: 3.4rem;
    height: 34px;
    width: 18px;
    opacity: 1;
}

.rc-slider-handle:active {
    box-shadow: 0 0 0.4rem 0.6rem rgba(232, 89, 47, 0.1);
}

.rc-slider-mark {
    top: 56px;
    font-family: "Inter_700Bold";
}

.rc-slider-mark-text,
.rc-slider-mark-text-active {
    color: white;
    opacity: 0.5;
}
.rc-slider-mark-text:hover,
.rc-slider-mark-text-active:hover {
    opacity: 1;
}

.rc-slider-tooltip {
    z-index: 5;
}

.rc-slider-tooltip-inner {
    box-shadow: none;
    background: var(--bg);
    border-radius: 14.5px;
    color: white;
    font-size: 13px;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter_700Bold";
    font-weight: bold;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    border-top-color: transparent;
}

/* WALLET MODAL */

.wallet-adapter-modal-wrapper {
    background: var(--black);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 20px;
}
.wallet-adapter-modal-title {
    font-size: 23px;
    line-height: 120%;
}
.wallet-adapter-modal-button-close {
    background: none;
}

.wallet-adapter-modal-middle-button {
    background: var(--primary) !important;
}

.wallet-adapter-modal-button-close svg {
    fill: white;
}

.wallet-adapter-modal-list {
    margin: 0;
}
.wallet-adapter-modal-list li {
    margin: 32px 40px;
}
.wallet-adapter-button {
    height: auto;
    padding: 0px 16px;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
    padding: 5px 20px;
    border: 2px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 10px !important;
    font-size: 19px !important;
    font-weight: 500 !important;
    height: auto;
    background: var(--bg);
}
.wallet-adapter-button:hover {
    background: none !important;
}

.animate {
    transition: all 0.3s ease-in-out;
}
.animate:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.Toastify__close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Toastify__toast-body > div:last-child {
    flex: none !important;
    height: 100% !important;
}
/* .Toastify__progress-bar {
    bottom: 5px;
} */
.Toastify__toast-body {
    width: 100%;
    display: block !important;
}

.gradient-border {
    --border-width: 3px;
    position: relative;
    border-radius: var(--border-width);
}
.gradient-border::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
        60deg,
        #5f86f2,
        #a65ff2,
        #f25fd0,
        #f25f61,
        #f2cb5f,
        #abf25f,
        #5ff281,
        #5ff2f0
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
}

@keyframes moveGradient {
    50% {
        background-position: 100% 50%;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 90deg;
    inherits: true;
}

@property --gradX {
    syntax: "<percentage>";
    initial-value: 50%;
    inherits: true;
}

@property --gradY {
    syntax: "<percentage>";
    initial-value: 0%;
    inherits: true;
}

.stroke-animation {
    border: 0.35rem solid;
    border-image: conic-gradient(
            from var(--angle),
            var(--c2),
            var(--c1) 0.1turn,
            var(--c1) 0.15turn,
            var(--c2) 0.25turn
        )
        30;
    animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
    100% {
        --angle: 420deg;
    }
}

@keyframes borderRadial {
    20% {
        --gradX: 100%;
        --gradY: 50%;
    }
    40% {
        --gradX: 100%;
        --gradY: 100%;
    }
    60% {
        --gradX: 50%;
        --gradY: 100%;
    }
    80% {
        --gradX: 0%;
        --gradY: 50%;
    }
    100% {
        --gradX: 50%;
        --gradY: 0%;
    }
}
